import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Container, Grid, Stack, Typography } from '@mui/material';

import ChatSection from '../components/Chat/ChatSection/ChatSection';
import ChatSidebar from '../components/Chat/ChatSidebar/ChatSidebar';
import Page from '../components/Page';
import { createNewChat, getChatMessages, getUserAllChats, markAsAllMessageRead, sendNewChatMessage } from '../client/FireStoreChat';
import { useAppDispatch, useAppSelector } from '../store';
import { getChatUsers, sendChatMessageNotification } from '../store/chat/api';
import { ChatMessage, ChatUser } from '../store/chat/api/interface';
import { UserInfo } from '../store/userInfo/api/interface';
import { getCommunityUsers } from '../store/communityUsers/api';
import { debounce } from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { decryptString } from '../utils/url';
import { markAsSeenNotificationThread, unreadNotificationCount } from '../store/notification/api';
import { getOSLink, isMobile } from '../utils/getMobileOperatingSystem';
import { AppColors, config } from '../utils/utils';
import { NotificationType, SharePostType, SocialShareType } from '../enum';
import { shareSocialFeedMessage, socialFeedUpdated } from '../store/social/socialSlice';
import { addCompanyFeedShare } from '../store/social/api';
import {
  checkIsKnoCardSocialConnectUserPlan,
  checkIsProUser,
} from '../utils/checkPro';
import ChatBackground from '../assets/chat/chat_bg.svg';

const Messaging: React.FC = () => {

    const [chatUsers, setChatUsers] = useState<ChatUser[]>([]);
    const navigate = useNavigate();
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const location = useLocation();

    const [filterKeyWord, setFilterKeyWord] = useState<string>('');

    const [chatUser, setChatUser] = useState<ChatUser | undefined>(undefined);

    const { shareSocialPostMessage, shareSocialPost } = useAppSelector((state) => state?.socialPost);
    const userInfo = useAppSelector((state) => state?.auth?.user);
    const chatChannels = useAppSelector((state) => state?.chat?.chatChannels);
    const isUsersLoading = useAppSelector((state) => state?.chat?.isLoading);
    const chatUserList = useAppSelector((state) => state?.chat?.userList);
    const dispatch = useAppDispatch();

    const { isLoading, isLoadMore, page } = useAppSelector((state) => state?.communityUsers);
    const communityUsers = useAppSelector((state) => state?.communityUsers.data) ?? [];

    const [searchParams] = useSearchParams();
    const [navigateToChat, setNavigateToChat] = useState<string | null>(null);


    useEffect(() => {
        if (
          userInfo &&
          !checkIsProUser(userInfo) &&
          !checkIsKnoCardSocialConnectUserPlan()
        ) {
          navigate('/dashboard/reports');
        }

    }, [userInfo]);


    useEffect(() => {


        if (searchParams.has('cid')) {
            try {
                const chatId = decryptString(searchParams.get('cid') ?? '');
                if (chatId != "") {
                    setNavigateToChat(chatId);
                    setFilterKeyWord('');
                    if (chatChannels.length > 0) {
                        updateChatUserList();
                    }
                }

            } catch (e) { }
        }

    }, [searchParams]);


    useEffect(() => {
        if (navigateToChat != null) {
            var isChatFound = false;
            chatUsers.forEach(element => {
                if (element.chatId == navigateToChat) {
                    setChatUser(element);
                    setNavigateToChat(null);
                    isChatFound = true;
                }
            });
            if (!isChatFound && navigateToChat != null && userInfo != null) {
                const splitData = navigateToChat.split("-");
                if (splitData.length == 2) {
                    try {
                        const chatUserId = (splitData[0] == `${userInfo.id}`) ? Number(splitData[1]) : Number(splitData[0]);
                        createNewChat({ id: chatUserId }, userInfo?.id);
                    } catch (e) { }
                }
            }
        }

    }, [chatUsers]);



    const updateChatUserList = () => {
        let updatedChatUsers: ChatUser[] = [];
        if (filterKeyWord.length > 0) {
            updatedChatUsers = loadChatInfoOnUser(communityUsers);
        } else {
            updatedChatUsers = loadChatInfoOnUser(chatUserList);
            updatedChatUsers.sort((itemOne, itemTwo) => {
                let valueOne = itemTwo?.lastMessage?.time?.seconds;
                let valueTwo = itemOne?.lastMessage?.time?.seconds;
                if (valueOne == undefined && valueTwo == undefined) {
                    return 0;
                } else if (valueOne != undefined && valueTwo == undefined) {
                    return 1;
                } else if (valueOne == undefined && valueTwo != undefined) {
                    return -1;
                } else if (valueOne == undefined) {
                    return -1;
                } else if (valueTwo == undefined) {
                    return 1;
                } else if (valueOne > valueTwo) {
                    return 1;
                } else if (valueOne < valueTwo) {
                    return -1;
                }
                return 0;
            });
        }



        setChatUsers(updatedChatUsers);
    }

    const loadChatInfoOnUser = (userList: UserInfo[]): ChatUser[] => {

        let updatedChatUsers: ChatUser[] = [];
        userList.forEach((chatUserItem) => {
            if (chatUserItem.id != userInfo?.id) {
                let isUserChatExist = false;
                chatChannels.forEach((chatChannelItem) => {
                    if (chatChannelItem.users?.includes(chatUserItem.id)) {
                        let userChatItem = {
                            id: chatUserItem.id,
                            chatId: chatChannelItem.id,
                            name: chatUserItem.name,
                            username: chatUserItem.username,
                            first_name: chatUserItem.first_name,
                            last_name: chatUserItem.last_name,
                            lastMessageTime: chatChannelItem.updated_at,
                            email: chatUserItem.email,
                            profile_picture: chatUserItem.profile_picture,
                            occupation: chatUserItem.occupation,
                            pined_by: Array.isArray(chatChannelItem.pined_by) ? chatChannelItem.pined_by : [],
                            lastMessage: chatChannelItem.last_message,
                            metaTags: chatUserItem.meta_tags,
                            chatChannelItem: chatChannelItem
                        };
                        updatedChatUsers.push(userChatItem);
                        isUserChatExist = true;
                        if (chatUser?.id == chatUserItem.id) {
                            setChatUser(userChatItem);
                        }
                    }
                });

                if (!isUserChatExist) {
                    let userChatItem = {
                        id: chatUserItem.id,
                        name: chatUserItem.name,
                        username: chatUserItem.username,
                        first_name: chatUserItem.first_name,
                        last_name: chatUserItem.last_name,
                        email: chatUserItem.email,
                        profile_picture: chatUserItem.profile_picture,
                        occupation: chatUserItem.occupation,
                        pined_by: [],
                        metaTags: chatUserItem.meta_tags,
                    };
                    updatedChatUsers.push(userChatItem);
                }
            }

        });
        return updatedChatUsers;
    };



    useEffect(() => {

        if (chatUser != undefined) {

            if (chatUser?.lastMessage?.unread && chatUser?.lastMessage?.unread > 0 && chatUser?.lastMessage?.from != userInfo?.id) {
                markAsAllMessageRead(chatUser, userInfo?.id ?? 0);
            }

            if (chatUser.chatChannelItem == undefined || chatUser.chatChannelItem == null) {
                createNewChat(chatUser, userInfo?.id ?? 0);
            }

            setChatMessages([]);
            let usChatMessage = getChatMessages(chatUser.chatId ?? '', (data: any[]) => {
                setChatMessages(data);
            });

            sendShareMessage();

            dispatch(
                markAsSeenNotificationThread({
                    receiver_id: userInfo?.id ?? 0,
                    sender_id: chatUser.id ?? 0
                })
            ).then(() => {
                dispatch(unreadNotificationCount({ receiver_id: userInfo?.id ?? 0 }));
            });


            return () => {
                if (usChatMessage != null) {
                    usChatMessage();
                }
            };
        }

    }, [chatUser?.chatId]);

    const sendShareMessage = async () => {

        if (searchParams.has(config.SHARE_KEY) && chatUser) {
            try {
                const sharePostType = decryptString(searchParams.get(config.SHARE_KEY) ?? '');
                switch (sharePostType) {
                    case SharePostType.social_post:
                        if (shareSocialPostMessage) {
                            try {
                                let message = `Hey ${chatUser?.first_name ?? ""}, ${shareSocialPostMessage}`;
                                sendNewChatMessage(chatUser, userInfo?.id ?? 0, message, [], true).then((result: ChatMessage) => {
                                    dispatch(sendChatMessageNotification({ message: result.text, notification_type: NotificationType.community, receiver_id: [result.to], sender_id: result.from }))
                                });


                                let companyFeedShareResponse = await dispatch(addCompanyFeedShare({
                                    postById: shareSocialPost?.company.owner?.id ?? 0,
                                    companyFeedId: shareSocialPost?.id ?? 0,
                                    actionType: SocialShareType.community,
                                    targetId: chatUser?.id
                                })).unwrap();

                                if (companyFeedShareResponse.status == 200 && companyFeedShareResponse.data.status == "success" && shareSocialPost != undefined) {
                                    if (shareSocialPost?.id == companyFeedShareResponse.data.data.company_feed_id) {
                                        dispatch(socialFeedUpdated({ ...shareSocialPost, shares_count: shareSocialPost.shares_count + 1 }))
                                    }
                                }


                            } catch (e) { }
                            dispatch(shareSocialFeedMessage({ socialPost: undefined, message: undefined }));
                        }
                        break;
                }
                navigate(`/dashboard/messaging`);
            } catch (e) { }

        }
    }

    useEffect(() => {

        if (chatChannels.length > chatUserList.length) {
            const userIdList = chatUserList.map((item) => item.id);
            const fetchUsersIdList: number[] = [];

            chatChannels.forEach((item) => {
                try {
                    if (item.users != null && item.users.length >= 2) {
                        let chatUserId = (item.users[0] == userInfo?.id) ? item.users[1] : item.users[0];
                        if (!userIdList?.includes(chatUserId)) {
                            fetchUsersIdList.push(chatUserId);
                        }
                    }
                } catch (e) { }
            });

            if (fetchUsersIdList.length > 0 && !isUsersLoading) {
                dispatch(getChatUsers({ user_ids: fetchUsersIdList }));
            }
        } else {
            updateChatUserList();
        }
    }, [chatChannels]);



    useEffect(() => {
        updateChatUserList();
    }, [chatUserList, communityUsers]);


    useEffect(() => {
        if (filterKeyWord.length == 0) {
            updateChatUserList();
        }
    }, [filterKeyWord]);

    const onSearchUserInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterKeyWord(e.target.value ?? "");
        handleLoadMoreSearchUsers(true, e.target.value ?? "");
    };


    const observer = useRef<IntersectionObserver>();
    const debounceWithSearch = debounce(onSearchUserInfo, 700);

    const lastElementRef = useCallback(
        // (*)
        (node: any) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && isLoadMore) {
                    handleLoadMoreSearchUsers(false, undefined);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, isLoadMore]
    );


    const handleLoadMoreSearchUsers = (isReload: boolean, searchKeyWord: string | undefined) => {
        let searchText = searchKeyWord ?? filterKeyWord;

        if (searchText.trim().length > 0) {
            dispatch(getCommunityUsers({
                from_lat: '0.0',
                from_lng: '0.0',
                filter_distance: "100000",
                sort_by: 'rating',
                page: isReload ? 1 : page,
                filter_keyword: searchText
            }));
        }

    };

    const handleDownloadApp = () => {
        const appLink = getOSLink();
        window.open(appLink) || window.location.replace(appLink);
    }


    return (
        <Page title='Messaging | KnoCard' showAppBar={true} >
            <Container maxWidth='xl' sx={{ height: 'calc(100vh - 120px)' }}>
                {isMobile() && <Stack alignItems={{ xs: "center", md: "end" }} onClick={handleDownloadApp} >
                    <Box display="flex" flexDirection="column" sx={{ maxWidth: "300px", mt: 4 }}>
                        <Typography sx={{ textAlign: 'center', color: "#ffffff", backgroundColor: "#000000", borderRadius: "50px", pt: 0.5, pb: 0.5 }} >Download</Typography>
                        <Stack display="flex" flexDirection="row" sx={{ mt: 1, mb: 1 }}>
                            <Box component={"img"} src='/static/images/android-download.png' sx={{ width: "50%" }} />
                            <Box component={"img"} src='/static/images/apple-download.png' sx={{ width: "50%" }} />
                        </Stack>
                        <Typography sx={{ textAlign: "center", color: "#6b6969" }}>Available on mobile device only</Typography>
                    </Box>
                </Stack>}
                {/* User List for Messages */}
                <Grid
                    container
                    direction={'row'}
                    sx={{ height: '100%' }}
                >
                    <Grid
                        item
                        xs={12}
                        md={chatUser ? 4 : 12}
                        sx={{
                            display: { xl: "unset", lg: "unset", md: "unset", sm: (chatUser ? "none" : undefined), xs: (chatUser ? "none" : undefined) },
                            mt: { xs: 3, md: 3 },
                            flex: 1,
                            borderRight: '1px solid #f5f5ff5',
                            height: { lg: '100%', md: 'calc(100% -  50px)' },

                        }}
                    >
                        <ChatSidebar chatUser={chatUser} chatUsers={chatUsers} setChatUser={setChatUser} onSearchChange={debounceWithSearch} lastElementRef={lastElementRef} />
                    </Grid>
                    {chatUser && <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{
                            mt: { xs: 3, md: 3, pl: 4 },
                            flex: 1,
                        }}
                    >
                        <Box
                            sx={{
                                ml: 2,
                                borderRadius: '16px',
                                backgroundColor: AppColors.whiteColor,
                                height: { lg: '100%', md: 'calc(100% -  50px)' },
                                overflowY: 'hidden',
                                backgroundImage: `url(${ChatBackground})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                            }}
                        >
                            <ChatSection chatUser={chatUser} chatMessages={chatMessages} setChatUser={setChatUser} />
                        </Box>
                    </Grid>}
                </Grid>
            </Container>
        </Page>
    );
};

export default Messaging;
