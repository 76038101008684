import { createSlice } from '@reduxjs/toolkit';
import {
  changeAffiliateGroupBanner,
  findAffiliateGroup,
  findAffiliateGroupPricing,
  getAffiliateGroupMedia,
} from './api';
import { initialState } from './api/interface';

const affiliateGroupSlice = createSlice({
  name: 'affiliateGroup',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    groupUpdated: (state, action) => {
      state.data.forEach((item: any, index: number) => {
        if (action.payload.id == item.id) {
          state.data[index] = action.payload;
        }
      });
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(findAffiliateGroup.pending, (state) => {
      state.isLoading = true;
      state.status = 'pending';
    });
    builder.addCase(findAffiliateGroup.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.data = [...action.payload.data.data];
      }
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
      state.status = 'succeeded';
    });
    builder.addCase(findAffiliateGroup.rejected, (state, _) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.status = 'failed';
    });

    builder.addCase(findAffiliateGroupPricing.pending, (state, _) => {
      state.isPricingLoading = true;
    });
    builder.addCase(findAffiliateGroupPricing.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.affiliateGroupPricing = [...action.payload.data.data];
      }
      state.isPricingLoading = false;
    });
    builder.addCase(findAffiliateGroupPricing.rejected, (state, _) => {
      state.isPricingLoading = false;
    });

    builder.addCase(changeAffiliateGroupBanner.pending, (state) => {});
    builder.addCase(changeAffiliateGroupBanner.fulfilled, (state, _) => {});
    builder.addCase(changeAffiliateGroupBanner.rejected, (state, action) => { });
    
    builder.addCase(getAffiliateGroupMedia.pending, (state) => {      
    });
    builder.addCase(getAffiliateGroupMedia.fulfilled, (state, action) => {
      if (action.payload.data.data != null) {
        state.affiliateGroupMedia = [...action.payload.data.data];
      }
    });
    builder.addCase(getAffiliateGroupMedia.rejected, (state, action) => {});
  },
});

export const { setStatus, reset, groupUpdated } = affiliateGroupSlice.actions;
export default affiliateGroupSlice.reducer;
