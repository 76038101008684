import React, { useEffect, useRef } from 'react';
import { Content } from '../store/post/api/interface';
import { GroupMediaItemContent } from '../store/corporateReporting/api/interface';

type IProps = {
  isDialogOpen: boolean;
  style: any;
  width: string;
  height: string;
  className: string;
  content: Content | GroupMediaItemContent;
  onVisibilityChange: (
    visibility: boolean,
    videoId: number,
    time: number,
    duration: number
  ) => void;
  handleOnplayVideo: (videoId: number, time: number, duration: number) => void;
  handleOnPauseOrCompleteVideo: (
    videoId: number,
    time: number,
    duration: number
  ) => void;
  isMediaPost?: boolean;
};

const MediaVideoComponent: React.FC<IProps> = ({
  isDialogOpen,
  style,
  width,
  height,
  className,
  content,
  onVisibilityChange,
  handleOnplayVideo,
  handleOnPauseOrCompleteVideo,
  isMediaPost = true,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            videoRef.current &&
            videoRef.current &&
            !videoRef.current.paused &&
            !videoRef.current.ended
          ) {
            // onVisibilityChange(entry.isIntersecting, content.id, videoRef.current.currentTime);
            videoRef.current.pause();
          }
        });
      },
      {
        threshold: 0.1, // Trigger the callback when at least 10% of the video is visible
      }
    );

    observer.observe(videoElement);

    // Cleanup observer on component unmount
    return () => {
      observer.unobserve(videoElement);
    };
  }, [onVisibilityChange]);

  useEffect(() => {
    if (
      !isDialogOpen &&
      videoRef.current &&
      !videoRef.current.paused &&
      !videoRef.current.ended
    ) {
      if (isMediaPost) {
        handleOnPauseOrCompleteVideo(
          content.id!,
          videoRef.current.currentTime,
          videoRef.current.duration || 0
        );
      }
      videoRef.current.pause();
    }
  }, [isDialogOpen]);

  return (
    <video
      ref={videoRef}
      controls
      width={width}
      height={height}
      style={style}
      className={className}
      poster={content.thumbnail ?? ''}
      onPlay={(props: any) => {
        if (isMediaPost) {
          handleOnplayVideo(content.id!, 0, props.currentTarget.duration || 0);
        }
      }}
      onPause={(props: any) => {
        if (isMediaPost) {
          handleOnPauseOrCompleteVideo(
            content.id!,
            props.currentTarget.currentTime,
            props.currentTarget.duration || 0
          );
        }
      }}
    >
      <source src={content?.link} type='video/mp4' />
      Your browser does not support the video tag.
    </video>
  );
};

export default MediaVideoComponent;
