import { UserInfo } from "../store/userInfo/api/interface";
import { config } from "./utils";



export const checkIsKnoCardSocialConnectUserPlan = (user?: UserInfo) => {

    return ((user?.current_subscription?.status?.toLowerCase() == "active" && user?.current_subscription?.code?.code == config.KNOCARD_SOCIAL_CONNECT) || (user?.active_subscription?.status?.toLowerCase() == "active" && user?.active_subscription?.plan?.code == config.KNOCARD_SOCIAL_CONNECT));
}

export const checkIsProUser = (user?: UserInfo) => {
    return ((user?.current_subscription?.status?.toLowerCase() == "active" && user?.current_subscription?.code?.code?.toLowerCase()?.includes("pro")) || (user?.active_subscription?.status?.toLowerCase() == "active" && user?.active_subscription?.plan?.code?.toLowerCase()?.includes("pro")));
}

// export const checkIsProProOrProOneOrProPlusUser = (user?: UserInfo) => {
//     return ((user?.current_subscription?.status?.toLowerCase() == "active" && (user?.current_subscription?.code?.code == config.PRO_PLUS || user?.current_subscription?.code?.code == config.PRO_1)) || (user?.active_subscription?.status?.toLowerCase() == "active" && (user?.active_subscription?.plan?.code == config.PRO_PLUS || user?.active_subscription?.plan?.code == config.PRO_1)));
// }

export const checkIsProOneUser = (user?: UserInfo) => {
    return ((user?.current_subscription?.status?.toLowerCase() == "active" && user?.current_subscription?.code?.code == config.PRO_1) || (user?.active_subscription?.status?.toLowerCase() == "active" && user?.active_subscription?.plan?.code == config.PRO_1));
}

export const checkIsProProOrProOneOrProPlusUser = (user?: UserInfo) => {
    return ((user?.current_subscription?.status?.toLowerCase() == "active" && (user?.current_subscription?.code?.code == config.PRO_PRO || user?.current_subscription?.code?.code == config.PRO_PLUS || user?.current_subscription?.code?.code == config.PRO_1)) || (user?.active_subscription?.status?.toLowerCase() == "active" && (user?.active_subscription?.plan?.code == config.PRO_PRO || user?.active_subscription?.plan?.code == config.PRO_PLUS || user?.active_subscription?.plan?.code == config.PRO_1)));
}



export const checkIsKnoCardLinkPurchased = (user?: UserInfo) => {
    return ((((user?.current_subscription?.status?.toLowerCase() == "active" && user?.current_subscription?.code?.code == config.PRO_PRO && user?.current_subscription?.knocard_link == 1) || (user?.active_subscription?.status?.toLowerCase() == "active" && user?.active_subscription?.plan?.code == config.PRO_PRO && user?.active_subscription?.knocard_link == 1))) || ((user?.current_subscription?.status?.toLowerCase() == "active" && user?.current_subscription?.code?.code == config.PRO_1) || (user?.active_subscription?.status?.toLowerCase() == "active" && user?.active_subscription?.plan?.code == config.PRO_1)));
}

export const checkIsKnoCardSpotlightPurchased = (user?: UserInfo) => {
  return (
    user?.current_subscription?.status?.toLowerCase() == 'active' &&
    user?.is_spotlight_purchased == 1
  );
};
