import { createAsyncThunk } from '@reduxjs/toolkit';
import { toastHandler } from '../../../utils/toastHandler';
import { axiosInstance } from '../../../client';
import { getItem } from '../../../utils/localStorage';
import { config } from '../../../utils/utils';
import { MediaType, PlatformType } from '../../../enum';
import { MediaPostContent } from '../../post/api/interface';

export const findAffiliateGroup = createAsyncThunk(
  'affiliateGroup/findAffiliateGroup',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/api/user/affiliate-group');

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const findAffiliateGroupPricing = createAsyncThunk(
  'affiliateGroup/findAffiliateGroupPricing',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        '/api/user/affiliate-group-pricing'
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const changeAffiliateGroupBanner = createAsyncThunk(
  'affiliateGroup/changeAffiliateGroupBanner',
  async (
    data: {
      groupId: number;
      vbcBackground: File;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = new FormData();
      request.append('vbcBackground', data.vbcBackground);
      const response = await axiosInstance.post(
        `/api/affiliate-group/change-banner/${data.groupId}`,
        request,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getAffiliateGroupMedia = createAsyncThunk(
  'affiliateGroup/getAffiliateGroupMedia',
  async (data: { groupId: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/api/affiliate-group-media/media/${data.groupId}`
      );

      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);

export const createGroupMedia = createAsyncThunk(
  'affiliateGroup/createGroupMedia',
  async (
    data: {
      user_id?: number;
      description: string;
      mediaData: MediaPostContent[];
      mediaPostThumb?: File;
      topic_id?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const request = new FormData();

      let userToken = getItem(config.AUTH_KEY);

      request.append('api_token', userToken);
      request.append('user_id', `${data.user_id}`);
      request.append('description', data.description);
      if (data.topic_id) {
        request.append('topic_id', `${data.topic_id}`);
      }

      var videoIndex = 0;
      var imageIndex = 0;
      var youtubeIndex = 0;
      var vimeoIndex = 0;
      let pdfIndex = 0;
      let assetIndex = 0;
      if (data.mediaPostThumb) {
        request.append(`post_thumbnail`, data.mediaPostThumb);
      }

      data.mediaData.forEach((contentFile: MediaPostContent, index: number) => {
        if (contentFile.platform == PlatformType.youtube) {
          if ((contentFile.thumbnail_link?.trim()?.length ?? 0) > 0) {
            request.append(
              `youtube_thumbnails_link[${youtubeIndex}]`,
              contentFile.thumbnail_link ?? ''
            );
          } else if (contentFile.thumbnail != null) {
            request.append(
              `youtube_thumbnails[${youtubeIndex}]`,
              contentFile.thumbnail
            );
          }
          request.append(`youtube[${youtubeIndex}]`, contentFile.link ?? '');
          request.append(`media_positions[${index}]`, contentFile.link ?? '');
          request.append(
            `media_titles[${contentFile.link}]`,
            contentFile.title
          );
          request.append(
            `media_description[${contentFile.link}]`,
            contentFile.description ?? ''
          );
          youtubeIndex++;
        } else if (contentFile.platform == PlatformType.vimeo) {
          if ((contentFile.thumbnail_link?.trim()?.length ?? 0) > 0) {
            request.append(
              `vimeo_thumbnails_link[${vimeoIndex}]`,
              contentFile.thumbnail_link ?? ''
            );
          } else if (contentFile.thumbnail != null) {
            request.append(
              `vimeo_thumbnails[${vimeoIndex}]`,
              contentFile.thumbnail
            );
          }
          request.append(`vimeo[${vimeoIndex}]`, contentFile.link ?? '');
          request.append(`media_positions[${index}]`, contentFile.link ?? '');
          request.append(
            `media_titles[${contentFile.link}]`,
            contentFile.title
          );
          request.append(
            `media_description[${contentFile.link}]`,
            contentFile.description ?? ''
          );
          vimeoIndex++;
        }

        if (contentFile.platform == PlatformType.asset) {
          if ((contentFile?.thumbnail_link?.trim().length ?? 0) > 0) {
            request.append(
              `asset_thumbnails_link[${assetIndex}]`,
              contentFile.thumbnail_link ?? ''
            );
          }
          request.append(`asset[${assetIndex}]`, `${contentFile.link}`);
          request.append(
            `media_positions[${index}]`,
            `${contentFile.link ?? ''}`
          );
          request.append(
            `media_titles[${contentFile.link}]`,
            `${contentFile.title}`
          );
          request.append(
            `asset_types[${contentFile.link}]`,
            `${contentFile.type}`
          );
          request.append(
            `media_description[${contentFile.link}]`,
            `${contentFile.description}`
          );
          assetIndex++;
        }

        if (contentFile.type == MediaType.image && contentFile.file != null) {
          var imageName = contentFile.file.name;
          request.append(`images[${imageIndex}]`, contentFile.file);
          request.append(`media_positions[${index}]`, imageName);
          request.append(`media_titles[${imageName}]`, contentFile.title);
          request.append(
            `media_description[${imageName}]`,
            contentFile.description ?? ''
          );
          imageIndex++;
        } else if (
          contentFile.type == MediaType.pdf &&
          contentFile.file != null
        ) {
          var pdfName = contentFile.file.name;
          request.append(`pdfs[${pdfIndex}]`, contentFile.file);
          request.append(`media_positions[${index}]`, pdfName);
          request.append(`media_titles[${pdfName}]`, contentFile.title);
          request.append(
            `media_description[${pdfName}]`,
            contentFile.description ?? ''
          );
          pdfIndex++;
        } else if (contentFile.file != null && contentFile.thumbnail != null) {
          var videoName = contentFile.file.name;
          request.append(`videos[${videoIndex}]`, contentFile.file);
          request.append(`thumbnails[${videoIndex}]`, contentFile.thumbnail);
          request.append(`media_positions[${index}]`, videoName);
          request.append(`media_titles[${videoName}]`, contentFile.title);
          request.append(
            `media_description[${videoName}]`,
            contentFile.description ?? ''
          );
          videoIndex++;
        }
      });

      const response = await axiosInstance.post(
        `/api/affiliate-group-media/store`,
        request,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response;
    } catch (error: any) {
      toastHandler(error);
      return rejectWithValue(error.message);
    }
  }
);
