import {
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
  Card,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import '@splidejs/react-splide/css';
import styles from "../styles/HamburgerMenuModal.module.css";
import { LoadingButton } from '@mui/lab';
import { FormikProvider, Form, useFormik } from 'formik';
import { getProfile, updatePassword } from '../store/auth/api';
import ChangePasswordSchema from '../validation/changePasswordSchema';
import { getItem } from '../utils/localStorage';
import { AppColors, config } from '../utils/utils';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Iconify from '../components/Iconify';
import { checkIsKnoCardLinkPurchased, checkIsKnoCardSpotlightPurchased, checkIsProUser } from '../utils/checkPro';
import { getStripeSubscriptionModifyLink, modifyKnocardLinkStripeSubscription } from '../store/purchase/api';
import { getPlanLabel, inputTextFieldStyle, openLinkOnNewTab, showErrorMessage, showSuccessMessage } from '../utils/utilMethods';
import InfoIcon from '@mui/icons-material/Info';


const Settings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useAppSelector((state) => state?.auth?.user);
  const isProUser = checkIsProUser(userInfo);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isManageBillingLoading, setManageBillingLoading] = useState(false);

  const [isKnoCardLinkSubscriptionchangeLoading, setKnoCardLinkSubscriptionchangeLoading] = useState(false);


  const isKnoCardLinkPurchased = checkIsKnoCardLinkPurchased(userInfo);
  const isKnoCardSpotlightPurchased = checkIsKnoCardSpotlightPurchased(userInfo);


  const handleManageBilling = async () => {
    setManageBillingLoading(true);
    const response = await dispatch(
      getStripeSubscriptionModifyLink({})
    ).unwrap().catch(() => {
      setManageBillingLoading(false);
    });
    if (response && response.status == 200) {
      if (response.data.payment_url) {
        window.open(`${response.data.payment_url}`, "_blank") || window.location.replace(`${response.data.payment_url}`);
      }
    }
    setManageBillingLoading(false);
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async () => {
      try {

        let userToken = getItem(config.AUTH_KEY);
        const response = await dispatch(
          updatePassword({
            api_token: userToken,
            user_id: userInfo?.id ?? 0,
            password: values.password,
            confirmed_password: values.confirmPassword,
          })
        ).unwrap();
        if (response.data.status === 'success') {
          showSuccessMessage('Password changed successfully.');
          formik.resetForm();
        } else {
          showErrorMessage(response?.data?.message);
        }
        setSubmitting(false);
      } catch (error: any) {
        showErrorMessage(error);
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
  } = formik;


  const handleLearnMoreKnoCardLink = () => {
    openLinkOnNewTab(`${config.BASE_URL}/knocard-link`)
  }
  
  const handleLearnMoreKnoCardSpotlight = () => {
    // openLinkOnNewTab(`${config.BASE_URL}/360`)
    openLinkOnNewTab(`https://knocard360.com/`);
  }

  const handleAddRemoveKnoCardLinkSubscription = async () => {
    setKnoCardLinkSubscriptionchangeLoading(true);
    const response = await dispatch(
      modifyKnocardLinkStripeSubscription({})
    ).unwrap().catch(() => {
      setKnoCardLinkSubscriptionchangeLoading(false);
    });
    if (response && response.status == 200) {
      dispatch(
        getProfile({
          user_id: userInfo?.id ?? 0,
          viewer_id: userInfo?.id ?? 0,
        })
      ).unwrap().then(() => {
        setKnoCardLinkSubscriptionchangeLoading(false);
      }).catch(() => {
        setKnoCardLinkSubscriptionchangeLoading(false);
      });
    }


  }

  return (
    <>
      <Page
        title='Exterbal Links | KnoCard'
        showAppBar={true}
      >


        <Grid container sx={{ mt: 5 }} >
          <Grid item md={6} xs={12}>
            <Card sx={{ m: 2 }}>
              <Box sx={{ p: 2 }}>
                <Typography
                  sx={{ textAlign: 'left', mb: 2, color: AppColors.blackColor }}
                >
                  Change Password
                </Typography>
                <FormikProvider value={formik}>
                  <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                    <Stack
                      direction='column'
                      alignItems='center'
                      justifyContent='space-between'
                      spacing={2}

                    >
                      <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        {...getFieldProps('password')}
                        placeholder='New Password'
                        InputProps={{
                          sx: inputTextFieldStyle(),
                          startAdornment: (
                            <InputAdornment position='start'>
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={() => setShowPassword((prev) => !prev)}
                                edge='end'
                              >
                                <Iconify
                                  icon={
                                    showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                      <TextField
                        fullWidth
                        type={showConfirmPassword ? 'text' : 'password'}
                        {...getFieldProps('confirmPassword')}
                        placeholder='Confirm Password'
                        InputProps={{
                          sx: inputTextFieldStyle(),
                          startAdornment: (
                            <InputAdornment position='start'>
                              <LockOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={() =>
                                  setShowConfirmPassword((prev) => !prev)
                                }
                                edge='end'
                              >
                                <Iconify
                                  icon={
                                    showConfirmPassword
                                      ? 'eva:eye-fill'
                                      : 'eva:eye-off-fill'
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                      />
                      <Stack
                        width={'100%'}
                        direction={'row'}
                        justifyContent={'end'}

                      >
                        <LoadingButton
                          fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                          loading={isSubmitting}
                          sx={{
                            borderRadius: 30,
                            width: "auto",
                            mt: 1
                          }}
                        >
                          Save
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Box>
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card sx={{ m: 2 }}>
              <Box sx={{ p: 2 }}>

                <Grid container gap={{ xs: 4, sm: 2 }} justifyContent={'center'}>
                  <Grid item md={5.8} xs={12}   >
                    <Typography
                      sx={{ textAlign: 'left', fontWeight: 500, mt: 1, mb: 2, color: AppColors.blackColor }}
                    >
                      Active Subscription
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {
                        (userInfo?.current_subscription?.code?.code == config.PRO_1 && isProUser) ?
                          <Box className={styles.packageTagPro}>
                            <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_1)}</span>
                            <img className={styles.badgeIcon} src="/static/images/crown.svg" alt="userBadge" />
                          </Box> : (userInfo?.current_subscription?.code?.code == config.PRO_PRO && isProUser) ? <Box className={styles.packageTagPro}>
                            <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_PRO)}</span>
                          </Box> : (userInfo?.current_subscription?.code?.code == config.PRO_BASIC && isProUser) ? <Box className={styles.packageTagPro}>
                            <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_BASIC)}</span>
                          </Box> : (userInfo?.current_subscription?.code?.code == config.PRO_PLUS && isProUser) ? <Box className={styles.packageTagPro}>
                            <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_PLUS)}</span>
                          </Box> : (userInfo?.current_subscription?.code?.code == config.PRO_FREE_TRIAL && isProUser) ? <Box className={styles.packageTagPro}>
                            <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_FREE_TRIAL)}</span>
                          </Box> : (userInfo?.current_subscription?.code?.code == config.KNOCARD_SOCIAL_CONNECT) ? <Box className={styles.packageTagFree}>
                            <span>{getPlanLabel(config.KNOCARD_SOCIAL_CONNECT)}</span>
                          </Box> : <Box className={styles.packageTagFree}>
                            <span>{getPlanLabel(config.FREE)}</span>
                          </Box>
                      }
                    </Box>
                  </Grid>

                  {(userInfo?.current_subscription?.code?.code == config.PRO_PRO && userInfo?.current_subscription?.status == "active" && userInfo?.current_subscription?.source == "stripe" && userInfo?.current_subscription?.type == "pro_pro_month") && (<Grid item md={5.8} xs={12} >
                    <Typography
                      sx={{ textAlign: 'left', fontWeight: 500, mt: 1, mb: 2, color: AppColors.blackColor }}
                    >KnoCard Link <InfoIcon onClick={handleLearnMoreKnoCardLink} sx={{ color: AppColors.primaryColor }} /></Typography>

                    <LoadingButton

                      size='large'
                      type='button'
                      variant='contained'
                      sx={{
                        ...(isKnoCardLinkSubscriptionchangeLoading ? {} : { background: `linear-gradient(180deg, ${AppColors.primaryColor}, ${AppColors.secondryColor})` }),
                        textAlign: 'center',
                        borderRadius: '100px',
                        width: "fit-content",
                        pl: 5,
                        pr: 5,
                        pt: 1,
                        pb: 1,
                        color: AppColors.whiteColor,
                        textTransform: 'unset'
                      }}
                      loading={isKnoCardLinkSubscriptionchangeLoading}
                      onClick={handleAddRemoveKnoCardLinkSubscription}
                    >{isKnoCardLinkPurchased ? "Unsubscribe" : "Subscribe Now"}</LoadingButton>
                  </Grid>)}

                  {isKnoCardSpotlightPurchased && <Grid item md={12} xs={12} >
                    <Typography
                    sx={{ textAlign: 'left', fontWeight: 500, mt: 1, mb: 2, color: AppColors.blackColor }}
                  >KnoCard Spotlight Purchased <InfoIcon onClick={handleLearnMoreKnoCardSpotlight} sx={{ color: AppColors.primaryColor }} /></Typography>
                  </Grid>}

                  <Grid item md={12} xs={12} >
                    {(userInfo?.current_subscription?.ends_at != null && userInfo?.current_subscription?.status == "active" && userInfo?.current_subscription?.source == "stripe") &&
                      <Box >
                        <Typography
                          sx={{ textAlign: 'left', fontWeight: 500, mt: 1, mb: 1, color: AppColors.blackColor }}
                        >
                          Manage Subscription
                        </Typography>
                        <LoadingButton
                          fullWidth
                          size='large'
                          type='button'
                          variant='contained'
                          loading={isManageBillingLoading}
                          onClick={handleManageBilling}
                          sx={{
                            borderRadius: 30,
                            width: "auto",
                            mt: 1
                          }}
                        >
                          Edit billing / Cancel subscription

                        </LoadingButton>
                      </Box>

                    }
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>


      </Page >
    </>
  );
};


export default Settings;
