export interface CreateProOneOrderRequest {
  purchase_code: string;
  api_token: string;
}

export interface CreateStripeOrderRequest {
  code: string;
  isAnnuallySubscription: boolean;
  api_token: string;
  redirect_url: string;
  is_knocard_link: boolean;
  is_knocard_spotlight: boolean;
  is_knocard_leaders_corner: boolean;
}

export interface StripeSubscriptionModifyLinkResponse {
  payment_url?: string;
  status?: string;
}

export interface SubscriptionPurchaseSuccessRequest {
  api_token: string;
  code: string;
  purchase_code: string;
  server_verification_data: string;
  source: string;
}

interface InitialState {
  isError: boolean;
  isLoading: boolean;
  errorMessage: string | undefined;
}

export const initialState: InitialState = {
  isError: false,
  isLoading: false,
  errorMessage: undefined,
};
